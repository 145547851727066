* {
  margin: 0;
  padding: 0;
  outline: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  /*-webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;*/
}
.user-select * {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
*:focus {
  -webkit-tap-highlight-color: transparent;
  outline: none;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}
:root {
  --Primary: #000000;
  --Secondary: #ffffff;
  --gray: #676666;
  --whiteAlpha: #fffbfbb3;
  --green: #4B864A;
}

html,body {
  width: 100%;
  font-family: 'Roboto', sans-serif;
  direction: ltr;
  font-weight: 500;
  /*font-size: 1.4vw;*/
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  /*overflow-x: hidden;*/

  background-color: #101010;
  /*color: #ffd0a08f;*/
  color: #edededb0;
}
.container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}
.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}
.row-reverse {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}
.column {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.jc-start {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}
.jc-center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.jc-end {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
.jc-between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.jc-around {
  -ms-flex-pack: distribute;
  justify-content: space-around;
}
.ai-start {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start !important;
}
.ai-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.ai-end {
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end !important;
}

.col-04 {
  -webkit-box-flex: 0.04;
  -ms-flex: 0.04;
  flex: 0.04;
}
.col-03 {
  -webkit-box-flex: 0.03;
  -ms-flex: 0.03;
  flex: 0.03;
}
.col-05 {
  -webkit-box-flex: 0.05;
  -ms-flex: 0.05;
  flex: 0.05;
}
.col-07 {
  -webkit-box-flex: 0.07;
  -ms-flex: 0.07;
  flex: 0.07;
}
.col-30 {
  -webkit-box-flex: 0.3;
  -ms-flex: 0.3;
  flex: 0.3;
}
.col-33 {
  -webkit-box-flex: 0.33;
  -ms-flex: 0.33;
  flex: 0.33;
}
.col-34 {
  -webkit-box-flex: 0.34;
  -ms-flex: 0.34;
  flex: 0.34;
}
.col-80 {
  -webkit-box-flex: 0.8;
  -ms-flex: 0.8;
  flex: 0.8;
}
.col-88 {
  -webkit-box-flex: 0.88;
  -ms-flex: 0.88;
  flex: 0.88;
}
.col-89 {
  -webkit-box-flex: 0.89;
  -ms-flex: 0.89;
  flex: 0.89;
}
.col-35 {
  -webkit-box-flex: 0.35;
  -ms-flex: 0.35;
  flex: 0.35;
}
.col-38 {
  -webkit-box-flex: 0.38;
  -ms-flex: 0.38;
  flex: 0.38;
}
.col-25 {
  -webkit-box-flex: 0.25;
  -ms-flex: 0.25;
  flex: 0.25;
}
.col-29 {
  -webkit-box-flex: 0.29;
  -ms-flex: 0.29;
  flex: 0.29;
}
.col-20 {
  -webkit-box-flex: 0.2;
  -ms-flex: 0.2;
  flex: 0.2;
}
.col-10 {
  -webkit-box-flex: 0.1;
  -ms-flex: 0.1;
  flex: 0.1;
}
.col-06 {
  -webkit-box-flex: 0.06;
  -ms-flex: 0.06;
  flex: 0.06;
}
.col-08 {
  -webkit-box-flex: 0.08;
  -ms-flex: 0.08;
  flex: 0.08;
}
.col-09 {
  -webkit-box-flex: 0.09;
  -ms-flex: 0.09;
  flex: 0.09;
}
.col-15 {
  -webkit-box-flex: 0.15;
  -ms-flex: 0.15;
  flex: 0.15;
}
.col-18 {
  -webkit-box-flex: 0.18;
  -ms-flex: 0.18;
  flex: 0.18;
}
.col-23 {
  -webkit-box-flex: 0.23;
  -ms-flex: 0.23;
  flex: 0.23;
}
.col-28 {
  -webkit-box-flex: 0.28;
  -ms-flex: 0.28;
  flex: 0.28;
}
.col-21 {
  -webkit-box-flex: 0.21;
  -ms-flex: 0.21;
  flex: 0.21;
}
.col-75 {
  -webkit-box-flex: 0.75;
  -ms-flex: 0.75;
  flex: 0.75;
}
.col-65 {
  -webkit-box-flex: 0.65;
  -ms-flex: 0.65;
  flex: 0.65;
}
.col-69 {
  -webkit-box-flex: 0.69;
  -ms-flex: 0.69;
  flex: 0.69;
}
.col-70 {
  -webkit-box-flex: 0.7;
  -ms-flex: 0.7;
  flex: 0.7;
}
.col-44 {
  -webkit-box-flex: 0.44;
  -ms-flex: 0.44;
  flex: 0.44;
}
.col-45 {
  -webkit-box-flex: 0.45;
  -ms-flex: 0.45;
  flex: 0.45;
}
.col-41 {
  -webkit-box-flex: 0.41;
  -ms-flex: 0.41;
  flex: 0.41;
}
.col-50 {
  -webkit-box-flex: 0.5;
  -ms-flex: 0.5;
  flex: 0.5;
}
.col-40 {
  -webkit-box-flex: 0.4;
  -ms-flex: 0.4;
  flex: 0.4;
}
.col-60 {
  -webkit-box-flex: 0.6;
  -ms-flex: 0.6;
  flex: 0.6;
}
.col-55 {

  -webkit-box-flex: 0.55;
  -ms-flex: 0.55;
  flex: 0.55;
}
.col-58 {
  -webkit-box-flex: 0.58;
  -ms-flex: 0.58;
  flex: 0.58;
}
.col-49 {
  -webkit-box-flex: 0.49;
  -ms-flex: 0.49;
  flex: 0.49;
}
.col-48 {
  -webkit-box-flex: 0.48;
  -ms-flex: 0.48;
  flex: 0.48;
}
.col-46 {
  -webkit-box-flex: 0.46;
  -ms-flex: 0.46;
  flex: 0.46;
}
.col-98 {
  -webkit-box-flex: 0.98;
  -ms-flex: 0.98;
  flex: 0.98;
}
.col-90 {
  -webkit-box-flex: 0.90;
  -ms-flex: 0.90;
  flex: 0.90;
}
.col-92 {
  -webkit-box-flex: 0.92;
  -ms-flex: 0.92;
  flex: 0.92;
}
.col-93 {
  -webkit-box-flex: 0.93;
  -ms-flex: 0.93;
  flex: 0.93;
}
.col-94 {
  -webkit-box-flex: 0.94;
  -ms-flex: 0.94;
  flex: 0.94;
}
.col-95 {
  -webkit-box-flex: 0.95;
  -ms-flex: 0.95;
  flex: 0.95;
}
.col-96 {
  -webkit-box-flex: 0.96;
  -ms-flex: 0.96;
  flex: 0.96;
}
.col-99 {
  -webkit-box-flex: 0.99;
  -ms-flex: 0.99;
  flex: 0.99;
}
.col-100 {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.width-19{
  width: 19%;
}
.width-20{
  width: 20%;
}
.width-23{
  width: 23%;
}
.width-30{
  width: 30%;
}
.width-33{
  width: 33%;
}
.width-40{
  width: 40%;
}
.width-50{
  width: 50%;
}
.width-70{
  width: 70%;
}
.height-100{
  height: 100%;
}


.text-start {
  text-align: start;
}
.text-center {
  text-align: center;
}
.text-end {
  text-align: end;
}
.text-right {
  text-align: right;
}
.text-justify {
  text-align: justify;
}
.text-gray {
  color: var(--gray);
}
.text-whiteAlpha {
  color: var(--whiteAlpha);
}
.text-green {
  color: var(--green);
}


.hide-scrollBar::-webkit-scrollbar {
  display: none;
}
.hide-scrollBar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.scrollView::-webkit-scrollbar {
  display: none;
}
.scrollView {
  -ms-overflow-style: none;
  scrollbar-width: none;
  margin: 0 !important;
}

.thumb-vertical {
  /*background-color: #cf1111 !important;*/
  background-color: #127010 !important;
  -webkit-transition: background-color 0.4s;
  -o-transition: background-color 0.4s;
  transition: background-color 0.4s;
  border-radius: 50vw;
}

.position-absolute {
  position: absolute;
}
.position-relative {
  position: relative;
}
.visibility-hidden {
  visibility: hidden;
}
.p-1 {
  padding: 1vh 1vw;
}
.p-05 {
  padding: 0.5vh 0.5vw;
}
.p-025 {
  padding: 0.25vh 0.25vw;
}
.px-1 {
  padding-right: 1vw;
  padding-left: 1vw;
}
.px-2 {
  padding-right: 2vw;
  padding-left: 2vw;
}
.px-3 {
  padding-right: 3vw;
  padding-left: 3vw;
}
.px-4 {
  padding-right: 4vw;
  padding-left: 4vw;
}
.px-5 {
  padding-right: 5vw;
  padding-left: 5vw;
}
.px-6 {
  padding-right: 6vw;
  padding-left: 6vw;
}
.px-7 {
  padding-right: 7vw;
  padding-left: 7vw;
}
.py-1 {
  padding-top: 1vh;
  padding-bottom: 1vh;
}
.py-2 {
  padding-top: 2vh;
  padding-bottom: 2vh;
}
.py-3 {
  padding-top: 3vh;
  padding-bottom: 3vh;
}
.py-4 {
  padding-top: 4vh;
  padding-bottom: 4vh;
}
.px-05 {
  padding-right: 0.5vw;
  padding-left: 0.5vw;
}
.py-05 {
  padding-top: 0.5vh;
  padding-bottom: 0.5vh;
}
.pl-1 {
  padding-left: 1vw;
}
.ltr .pl-1 {
  padding-right: 1vw;
  padding-left: initial;
}
.pr-1 {
  padding-right: 1vw;
}
.pr-5 {
  padding-right: 5vw;
}
.ltr .pr-1 {
  padding-left: 1vw;
  padding-right: initial;
}
.pr-2 {
  padding-right: 2vw;
}
.ltr .pr-2 {
  padding-left: 2vw;
  padding-right: initial;
}

.ml-1 {
  margin-left: 1vw;
}
.ml-2 {
  margin-left: 2vw;
}
.ml-3 {
  margin-left: 3vw;
}
.ml-05 {
  margin-left: 0.5vw;
}
.ltr .ml-05 {
  margin-right: 0.5vw;
  margin-left: initial;
}
.mr-05 {
  margin-right: 0.5vw;
}
.ltr .mr-05 {
  margin-left: 0.5vw;
  margin-right: initial;
}
.mr-1 {
  margin-right: 1vw;
}
.ltr .mr-1 {
  margin-left: 1vw;
  margin-right: initial;
}
.mr-2 {
  margin-right: 2vw;
}
.ltr .mr-2 {
  margin-left: 2vw;
  margin-right: initial;
}

.mr-025 {
  margin-right: 0.25vw;
}
.ltr .mr-025 {
  margin-left: 0.25vw;
  margin-right: initial;
}

.pl-05 {
  padding-left: 0.5vw;
}
.ltr .pl-05 {
  padding-right: 0.5vw;
  padding-left: initial;
}

.pr-05 {
  padding-right: 0.5vw;
}
.ltr .pr-05 {
  padding-left: 0.5vw;
  padding-right: initial;
}
.pt-1 {
  padding-top: 1vh;
}
.pt-2 {
  padding-top: 2vh;
}
.pt-05 {
  padding-top: 0.5vh;
}
.pb-1 {
  padding-bottom: 1vh;
}
.pb-2 {
  padding-bottom: 2vh;
}
.pb-5 {
  padding-bottom: 5vh;
}
.mt-05 {
  margin-top: 0.5vh;
}
.mt-1 {
  margin-top: 1vh;
}
.mt-2 {
  margin-top: 2vh;
}
.mb-05 {
  margin-bottom: 0.5vh;
}
.mb-1 {
  margin-bottom: 1vh;
}
.mb-2 {
  margin-bottom: 2vh;
}
.mb-4 {
  margin-bottom: 4vh;
}
.mx-05 {
  margin-right: 0.5vw;
  margin-left: 0.5vw;
}
.mx-025 {
  margin-right: 0.25vw;
  margin-left: 0.25vw;
}
.mx-1 {
  margin-right: 1vw;
  margin-left: 1vw;
}
.mx-2 {
  margin-right: 2vw;
  margin-left: 2vw;
}
.mx-3 {
  margin-right: 3vw;
  margin-left: 3vw;
}
.my-1 {
  margin-top: 1vh;
  margin-bottom: 1vh;
}
.my-2 {
  margin-top: 2vh;
  margin-bottom: 2vh;
}
.my-4 {
  margin-top: 4vh;
  margin-bottom: 4vh;
}
.my-05 {
  margin-top: 0.5vh;
  margin-bottom: 0.5vh;
}

li {
  list-style: none;
}


.direction-ltr {
  direction: ltr;
}
.direction-rtl {
  direction: rtl;
}

.font-weight-bold {
  font-weight: bold !important;
}
.font-weight-300 {
  font-weight: 300 !important;
}
.font-weight-400 {
  font-weight: 400 !important;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal !important;
}

.cursor-pointer {
  cursor: pointer;
}
.cursor-not-allowed {
  cursor: not-allowed;
}

.hover:hover{
  color: #127010;
}