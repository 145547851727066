.wrapper{
    height: 100vh;
    background-repeat: no-repeat repeat;
    background-position: center center;
    background-size: cover;
    opacity: 0.1;
}
.moveImage {
    animation-name: MOVE-BG;
    animation-duration: 15s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
}
@keyframes MOVE-BG {
    from {
        background-position: center top;
    }
    to {
        background-position: center 1200px;
    }
}
.container{
    top: 0;
    bottom: 0;
}
.content{
    width: 85%;
    top: 5%;
    position: absolute;
    bottom: 5%;
    background-color: #212121a8;
    border-radius: 8px;
    box-shadow: inset 0 0 8px 1px #2b2d2e;
}
.header{
    height: 15%;
    /*background-color: red;*/
    font-weight: bold !important;
    font-size: 5vw;
    color: #127010;
}
.body{
    height: 70%;
    /*background-color: orange;*/
}
.footer{
    height: 15%;
    /*background-color: orangered;*/
}
.inputGroup{
    width: 80%;
}
.inputGroup.result{
    width: 80%;
}
.result img{
    width: 15vw
}
.input{
    /*height: 5vh;*/
    width: 100%;

}
.inputGroup input{
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
    width: 100%;
    border-radius: 4px;
    /*border: 0.001vh solid #686868;*/
    background-color: rgba(9, 10, 9, 0.58);
    color: #b8b5b5;
    font-size: 3.5vw;
    padding: 2.2vh 3vw;
    border: none;
    margin: 1vh 0
}
.inputGroup input:focus{
    border: 0.001vh solid #127010;
}
.alert{
    width: 100%;
    font-size: 3.3vw;
    color: #d81414b8;
    text-align: left;
}
.alert span{
    margin: -0.25vh 0;
}
.footer button{
    padding: 1.75vh 8vw;
    background-color: #127010;
    border-radius: 4px;
    border: none;
    color: #b8b5b5;
    font-size: 3.5vw;
}
.footer.result button{
    background-color: #c97723;
    color: #131313;

}
.label{
    text-align: center;
}
.link{
    background-color: #070c07a6;
    position: absolute;
    padding: 0.75vh 4vw;
    border-radius: 50px;
    top: 17%;
}
.hover:hover{
    color: #e18620;
}